.slick-slider {
  @include hover-focus { .slick-arrow { opacity: 0.5; } }

  .slick-arrow {
    border-radius: $border-radius-capsule;
    width: 3rem;
    height: 3rem;
    background-color: $white !important;
    transition: opacity 0.4s ease-in-out;
    box-shadow: 0.125rem 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
    color: $gray-700;
    opacity: 0;
    z-index: 999;
    &:before { display: none }

    @include hover-focus { opacity: 1; }
  }
  .slick-next, .slick-prev {
    &:after {
      content: '';
      position: absolute;
      height: 0.5rem;
      width: 0.5rem;
      left: 50%;
      top: 50%;
      background-color: transparent;
      border-right: 1px solid $black;
      border-top: 1px solid $black;
    }
  }

  .slick-next:after { transform: translate3d(-60%, -50%, 0) rotate(45deg); }
  .slick-prev:after { transform: translate3d(-40%, -50%, 0) rotate(-135deg); }
}
