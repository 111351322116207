/*-----------------------------------------------
|   Flex
-----------------------------------------------*/
.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-between-center {
  align-items: center;
  justify-content: space-between;
}