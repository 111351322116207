/*-----------------------------------------------
|   Navigation bar
-----------------------------------------------*/
.navbar-glass{ background-color: rgba($body-bg, 0.96); }
.safari{
  .navbar-glass{
    // Issue after safari update
    // background-color: rgba($body-bg, 0.82);
    // backdrop-filter: blur(5px);
    box-shadow: 0 0.5rem 0.5rem -0.5rem rgba(0, 0, 0, 0);
  }
}
.navbar-brand{ font-weight: $navbar-brand-font-weight; }

.navbar-glass-shadow{ box-shadow: 0 0.5rem 0.5rem -0.5rem rgba($black, 0.2) !important; }
